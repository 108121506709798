import * as React from "react";
import HomePage from "../components/pages/HomePage";

const locale = "en";

function IndexPage(props) {
  return <HomePage locale={locale} />;
}

export default IndexPage;
